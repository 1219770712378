ion-app.charcoalGray {
  --ion-color-primary: #434343;
  --ion-color-primary-rgb: 67, 67, 67;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3b3b3b;
  --ion-color-primary-tint: #565656;

  --ion-color-secondary: #3b3b3b;
  --ion-color-secondary-rgb: 59, 59, 59;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #343434;
  --ion-color-secondary-tint: #4f4f4f;

  --ion-color-tertiary: #4f4f4f;
  --ion-color-tertiary-rgb: 79, 79, 79;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #464646;
  --ion-color-tertiary-tint: #616161;

  --ion-color-success: #2dd55b;
  --ion-color-success-rgb: 45, 213, 91;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28bb50;
  --ion-color-success-tint: #42d96b;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #c5000f;
  --ion-color-danger-rgb: 197, 0, 15;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad000d;
  --ion-color-danger-tint: #cb1a27;

  --ion-color-light: #f6f8fc;
  --ion-color-light-rgb: 246, 248, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8dade;
  --ion-color-light-tint: #f7f9fc;

  --ion-color-medium: #5f5f5f;
  --ion-color-medium-rgb: 95, 95, 95;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #545454;
  --ion-color-medium-tint: #6f6f6f;

  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;
}