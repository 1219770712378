/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@use 'src/sizes' as sizes;

/* Core CSS required for Ionic components to work properly */
@use "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@use "@ionic/angular/css/normalize.css";
@use "@ionic/angular/css/structure.css";
@use "@ionic/angular/css/typography.css";
@use '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@use "@ionic/angular/css/padding.css";
@use "@ionic/angular/css/float-elements.css";
@use "@ionic/angular/css/text-alignment.css";
@use "@ionic/angular/css/text-transformation.css";
@use "@ionic/angular/css/flex-utils.css";

$headerHeight: 70px;

@media print {

  ion-header,
  ion-tab-bar {
    display: none;
  }
}

.desktop {
  display: flex;

  &.desktop-block {
    display: block;
  }

  .searchbar-cancel-button {
    display: none !important;
  }

  @include sizes.tablet {
    display: none !important;
  }
}

.mobile {
  display: none;

  @include sizes.tablet {
    display: block;

    &.mobile-flex {
      display: flex;
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-card {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  color: #000;
  background: var(--ion-color-light);
  border-radius: var(--app-card-radius);
  padding: var(--app-default-padding);
  width: fit-content;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-background-color-step-200);
  --swiper-pagination-color: var(--ion-color-secondary);
  --swiper-pagination-progressbar-bg-color: rgb(var(--ion-text-color-rgb, 0, 0, 0) 0.25);
  --swiper-scrollbar-bg-color: #d6d6d6;
  --swiper-scrollbar-drag-bg-color: rgb(var(--ion-text-color-rgb, 0, 0, 0) 0.5);
  --swiper-navigation-color: var(--ion-color-secondary);
}

.swiper-container-horizontal>.swiper-scrollbar {
  border-radius: 2px;
  height: 5px;
  width: 200px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.dark {

  ion-button {
    --color: --ion-color-secondary;
    --border-color: --ion-color-secondary;
  }
}

ion-tab-bar {
  border-top: 0;
  margin-top: -0.5px;
}

ion-toolbar,
ion-content {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
}

ion-footer {
  max-height: 64px;
}

ion-item {
  --border-radius: var(--app-card-radius);
}

ion-skeleton-text.item {
  border-radius: var(--app-card-radius);
  height: 60px;
  margin-bottom: 0.5em;
}

ion-loading {
  &.full-loading {
    --width: 95%;
    --max-width: 100%;
    --height: 105%;
  }
}

h2 {
  margin: 0.6em 0;
}

h3,
h4,
h5,
h6 {
  margin: 0.6em 0;
}

ion-searchbar {
  --box-shadow: none !important;
  --color: var(--ion-color-secondary-contrast) !important;
  --background: var(--ion-color-secondary) !important;
  --icon-color: var(--ion-color-secondary) !important;
  --placeholder-color: var(--ion-color-secondary-contrast) !important;
  --border-radius: var(--app-card-radius) !important;
  --clear-button-color: var(--ion-color-secondary-contrast) !important;
  --cancel-button-color: var(--ion-color-secondary-contrast) !important;
  padding-inline: 0 0 !important;

  input {
    padding-top: var(--app-default-padding) !important;
    padding-bottom: var(--app-default-padding) !important;
  }
}

ion-popover {

  ion-content {
    --padding-start: 0.5em;
    --padding-end: 0.5em;
  }
}

ion-datetime {
  color: #222 !important;
}

ion-badge.count {
  --background: var(--ion-color-danger);
  --color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;

  .cdk-virtual-scroll-content-wrapper {
    max-width: 100% !important;
  }
}

.canvas-modal {
  --width: 1100px;

  @media screen and (height >=500px) {
    --min-height: 700px;
  }
}

ion-modal {
  --border-radius: var(--app-card-radius);

  &.modal-fullscreen {
    --border-radius: 0;
    --height: 100%;
    --width: 100%;
  }

  &.modal-90 {
    --height: 90%;
    --width: 90%;
  }

  &.small-modal {
    --border-radius: var(--app-card-radius);
    --height: 25%;
    --width: 80%;
  }

  &.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
  }

  &.transparent-modal {
    --background: rgb(44 39 45 / 20%);

    &::part(content) {
      backdrop-filter: blur(12px);
    }
  }

  &.modal-tall {
    --height: 100%;
  }

  @include sizes.tablet {
    --border-radius: 2px;
  }
}

ion-modal.picker-modal {
  --height: auto;
  align-items: end;

  ion-toolbar {
    --background: var(--ion-color-light);
    --color: var(--ion-color-light-contrast);
  }

  ion-picker {
    margin-bottom: var(--ion-safe-area-bottom);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgb(44 39 45 / 20%);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: rgb(44 39 45 / 20%);
}

.full-width {
  width: 100%;
}

.helper-title {
  font-size: 18px;
}

.ion-margin-top2 {
  margin-top: 2em;
}

.ion-margin-top05 {
  margin-top: 0.5em;
}

.gap025 {
  gap: 0.25em;
}

.ion-padding05 {
  padding: 0.5em;
}

.link {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.ionic-selectable {
  min-width: 150px;
}

.ion-margin-end05 {
  margin-right: 0.5em;
}

.ion-margin-start05 {
  margin-left: 0.5em;
}

ionic-selectable[hidden] {
  display: none;
}

app-schedule,
app-machines-schedule {

  ion-chip {
    overflow: visible;
  }
}

ion-item.button {
  --min-height: 60px;
}

ion-item {
  --min-height: 55px;
}

.ios {
  ion-footer {
    ion-buttons {
      ion-button {
        ion-icon {
          font-size: 3em !important;
        }
      }
    }
  }
}

ion-segment.inside-container {
  margin-top: calc(-1 * var(--app-default-padding, 0));
  margin-bottom: var(--app-default-padding);
  margin-left: 0;
  margin-right: 0;
}

ion-segment {
  --background: var(--ion-color-primary);
  display: flex;
  gap: 0.5em;
  scrollbar-width: none;
  height: 2.875em;
  margin: 0 var(--app-default-padding);
  margin-bottom: 0.5em;

  ion-segment-button {
    flex: none;
    font-size: 0.8em;

    --padding-top: 0.5em;
    --padding-bottom: 0.5em;
    --padding-start: 2em;
    --padding-end: 2em;
  }

  ion-button {
    --color: var(--ion-color-primary);
    --background: var(--ion-color-primary-contrast);
    --background-hover: var(--ion-color-light);
    margin: 2px 0;
    font-size: min(0.8125rem, 39px);
    margin-left: auto;
  }
}

ion-segment-button.ios {
  --border-radius: 20px;
  --background: rgba(0, 0, 0, 0.10);
  --indicator-color: var(--ion-color-light);
  --color-checked: #000;
  --margin-top: 0.25em;
  --margin-bottom: 0.25em;
}

ion-toolbar.back-container {
  padding-left: 0.25em;
  padding-right: var(--app-default-padding);
}

ion-back-button {
  --background: transparent;
  --color: var(--ion-color-primary-contrast);
  --padding-start: 0;
  --padding-end: 0.5em;
  --padding-top: 0.2em;
}

ion-button {
  --box-shadow: none;
  text-transform: none;

  &:not(.button-has-icon-only) {
    // Round
    --border-radius: 64px;
    --padding-top: 0;
    --padding-start: 26px;
    --padding-end: 26px;
    --padding-bottom: 0;
  }
}

ion-button.back {
  --background: transparent;
  --color: var(--ion-color-primary-contrast);
  --padding-start: 0.5em;
  --padding-end: 0.5em;

  margin-right: 1em;
  width: 41px;
}

/** Colors */

.ion-color-messages {
  --ion-color-base: var(--ion-color-messages) !important;
  --ion-color-base-rgb: 24, 112, 14 !important;
  --ion-color-contrast: #fff !important;
  --ion-color-contrast-rgb: 255, 255, 255 !important;
  --ion-color-shade: #15630c !important;
  --ion-color-tint: #2f7e26 !important;
}

.ion-color-calendar {
  --ion-color-base: var(--ion-color-calendar) !important;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
}

.ion-color-pay {
  --ion-color-base: var(--ion-color-pay) !important;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
}

.ion-color-sites {
  --ion-color-base: var(--ion-color-sites) !important;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
}

.ion-color-reports {
  --ion-color-base: var(--ion-color-reports) !important;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
}

.ion-color-schedule {
  --ion-color-base: var(--ion-color-schedule) !important;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
}

.ion-color-clients {
  --ion-color-base: var(--ion-color-clients) !important;
  --ion-color-contrast: #fff;
  --ion-color-contrast-rgb: 255, 255, 255;
}

.ion-color-white {
  --ion-color-base: #fff !important;
  --ion-color-contrast: #222;
  --ion-color-contrast-rgb: 0, 0, 0;
}

ion-badge {
  border-radius: 1em;

  --padding-end: 1em;
  --padding-start: 1em;
}

ion-note {
  --color: #ccc;
}